import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Sophrologie from '@/views/Sophrologie.vue'
/*
import ReflexesArchaic from '@/views/ReflexesArchaic.vue'
*/
import About from '@/views/About.vue'
/*
import Contact from '@/views/Contact.vue'
*/

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/sophrologie', name: 'Sophrologie', component: Sophrologie }/*,
  { path: '/reflexes-archaiques', name: 'ReflexesArchaic', component: ReflexesArchaic }*/,
  { path: '/about', name: 'About', component: About }/*,
  { path: '/contact', name: 'Contact', component: Contact }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
    <div class="container px-4 px-lg-5">
      <img src="../assets/logo-LD.jpg" alt="Logo">
      <router-link class="navbar-brand" style="margin-left: 10px;" to="/">Stephrologie</router-link>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ms-auto my-2 my-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/" active-class="active">Accueil</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/sophrologie" active-class="active">Sophrologie</router-link>
          </li>
          <!--
          <li class="nav-item">
            <router-link class="nav-link" to="/reflexes-archaiques" active-class="active">Réflexes Archaïques</router-link>
          </li>
          -->
          <li class="nav-item">
            <router-link class="nav-link" to="/about" active-class="active">Qui suis-je ?</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact" active-class="active">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'HeaderComponent',
  mounted() {
    this.navbarShrink();
    document.addEventListener('scroll', this.navbarShrink);

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector('.navbar-toggler');
    const responsiveNavItems = [].slice.call(
        document.querySelectorAll('#navbarResponsive .nav-link')
    );
    responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener('click', () => {
        if (window.getComputedStyle(navbarToggler).display !== 'none') {
          navbarToggler.click();
        }
      })
    })
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.navbarShrink);
  },
  methods: {
    navbarShrink() {
      const navbarCollapsible = document.querySelector('#mainNav');
      if (!navbarCollapsible) {
        return;
      }
      if (window.scrollY === 0) {
        navbarCollapsible.classList.remove('navbar-shrink');
      } else {
        navbarCollapsible.classList.add('navbar-shrink');
      }
    }
  }
}</script>

<style>
/* Ajoutez vos styles ici */
</style>

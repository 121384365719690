<template>
  <section id="index" class="home-section">
    <div class="container mt-5 pt-5">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2>Bienvenue sur le site de Stéphanie Martin, Sophrologue</h2>
          <p>Découvrez la sophrologie, une méthode de relaxation dynamique qui vous aide à trouver un équilibre entre votre corps et votre esprit. Que vous cherchiez à gérer le stress, améliorer votre sommeil ou simplement vous recentrer, Stéphanie Martin est là pour vous accompagner dans votre parcours de bien-être.</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4 text-center">
          <img src="../assets/img/bg-masthead.jpeg" alt="Relaxation" class="img-fluid rounded-circle">
          <h3>Relaxation</h3>
          <p>Apprenez des techniques de relaxation pour réduire le stress et retrouver votre calme intérieur.</p>
        </div>
        <div class="col-lg-4 text-center">
          <img src="../assets/img/bg-masthead.jpeg" alt="Concentration" class="img-fluid rounded-circle">
          <h3>Concentration</h3>
          <p>Développez votre capacité de concentration et améliorez votre productivité quotidienne.</p>
        </div>
        <div class="col-lg-4 text-center">
          <img src="../assets/img/bg-masthead.jpeg" alt="Bien-être" class="img-fluid rounded-circle">
          <h3>Bien-être</h3>
          <p>Atteignez un état de bien-être général grâce à des exercices adaptés à vos besoins.</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-12 text-center">
          <h2>Prendre rendez-vous</h2>
          <p>Vous souhaitez en savoir plus ou prendre rendez-vous pour une séance de sophrologie ? Contactez-moi dès aujourd'hui.</p>
          <a href="#" class="btn btn-primary">Contactez-moi</a>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "AboutComponent"
}
</script>

<style scoped>

</style>
<template>
  <div>
    <header class="masthead bg-sophrologie-1" id="index">
      <div class="container px-4 px-lg-5" style="height: calc(100% - 50px)">
        <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
          <div class="col-lg-8 align-self-end">
            <h1 class="font-weight-bold">La sophrologie</h1>
            <hr class="divider" />
          </div>
          <div class="col-lg-10 align-self-baseline" style="padding-bottom: 50px;">
            <p class="font-weight-bold mb-5 p-3 rounded-5 bg-white-transparent">
              La sophrologie, créée en 1960 par le neuropsychiatre Alfonso Caycedo, est une méthode douce et naturelle
              qui puise ses inspirations dans des techniques ancestrales telles que le yoga, l'hypnose et le zen. Elle
              vise à harmoniser le corps et l'esprit par des exercices de relaxation dynamique, de respiration et de
              visualisation positive. Cette pratique accessible à tous permet de développer une meilleure connaissance
              de soi, de renforcer le bien-être au quotidien et de gérer plus sereinement les situations de stress. En
              libérant le potentiel de votre conscience, la sophrologie vous aide à vivre de manière plus épanouie et
              équilibrée.
            </p>
            <router-link class="btn btn-primary btn-xl" to="/about">Découvrez comment je pratique la sophrologie</router-link>
          </div>
        </div>
      </div>
      <div class="waves"></div>
    </header>

    <section class="page-section p-0" id="about">
      <div class="py-3 px-4 px-lg-5 bg-primary">
        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="text-white mt-0">Qu'est-ce que la sophrologie ?</h2>
            <hr class="divider divider-light" />
            <p class="text-white-75 fw-bold mb-4">La sophrologie est une méthode psycho corporelle du corps et de l’esprit, qui
              va permettre à chacun d’utiliser ses propres ressources pour retrouver un bien-être physique et
              émotionnel, d’accéder à son plein potentiel pour affronter les défis de la vie et avoir une meilleure
              connaissance de soi.<br><br>

              En sophrologie, nous utilisons des exercices de respiration, de la détente musculaire, de la relaxation
              dynamique et de la visualisation.<br><br>

              Sa pratique régulière amène à une meilleure connaissance de soi, une meilleure gestion émotionnelle, un
              mieux-être et permet d’accéder à la relaxation. Elle permet à chacun d’accéder à ses propres capacités
              pour atteindre ses objectifs et être en accord avec ses besoins profonds.</p>
          </div>
        </div>
      </div>
    </section>
    <div class="waves-2"></div>
  </div>
</template>

<script>
export default {
  name: 'HomeComponent'
}
</script>

<style>
.masthead.bg-sophrologie-1 {
  background: radial-gradient(rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 70%), url("../assets/img/sophrologie/bg-1.jpeg");
}
</style>

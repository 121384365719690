<template>
  <div>
    <header class="masthead bg-home-1" id="index">
      <div class="container px-4 px-lg-5" style="height: calc(100% - 50px)">
        <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
          <div class="col-lg-8 align-self-end">
            <h1 class="font-weight-bold">Libérez le potentiel de votre bien-être</h1>
            <hr class="divider" />
          </div>
          <div class="col-lg-10 align-self-baseline" style="padding-bottom: 50px;">
            <p class="font-weight-bold mb-5 p-3 rounded-5 bg-white-transparent">
              Je vous propose une méthode douce pour harmoniser votre corps et votre esprit. À travers des techniques de relaxation et de visualisation, ensemble nous réduirons votre stress et éveillerons votre bien-être intérieur. Transformez votre quotidien et accédez à une sérénité durable dès aujourd'hui.
            </p>
            <router-link class="btn btn-primary btn-xl" to="/about">Découvrez comment je pratique la sophrologie</router-link>
          </div>
        </div>
      </div>
      <div class="waves"></div>
    </header>

    <section class="page-section p-0" id="about">
      <div class="py-3 px-4 px-lg-5 bg-primary">
        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="text-white mt-0">Qu'est-ce que la sophrologie ?</h2>
            <hr class="divider divider-light" />
            <p class="text-white-75 fw-bold mb-4">La sophrologie est une méthode psycho corporelle du corps et de l’esprit, qui
              va permettre à chacun d’utiliser ses propres ressources pour retrouver un bien-être physique et
              émotionnel, d’accéder à son plein potentiel pour affronter les défis de la vie et avoir une meilleure
              connaissance de soi.<br><br>

              En sophrologie, nous utilisons des exercices de respiration, de la détente musculaire, de la relaxation
              dynamique et de la visualisation.<br><br>

              Sa pratique régulière amène à une meilleure connaissance de soi, une meilleure gestion émotionnelle, un
              mieux-être et permet d’accéder à la relaxation. Elle permet à chacun d’accéder à ses propres capacités
              pour atteindre ses objectifs et être en accord avec ses besoins profonds.</p>
          </div>
        </div>
      </div>
    </section>
    <div class="waves-2"></div>

    <div class="d-block pt-4">
      <h2 class="text-center mb-5">Dans un monde en constante évolution, je vous aide à :</h2>
      <div class="row my-4">
        <div class="col-4 text-end pr-3">
          <img src="../assets/img/sophrologie/icons/yoga.png" alt="yoga" style="height: 40px">
        </div>
        <div class="col-8 d-flex align-content-center">
          <h4 class="p-0 m-0">Gérer votre stress</h4>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-4 text-end pr-3">
          <img src="../assets/img/sophrologie/icons/bear.png" alt="bear" style="height: 40px">
        </div>
        <div class="col-8 d-flex align-content-center">
          <h4 class="p-0 m-0">Renforcer votre confiance en vous</h4>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-4 text-end pr-3">
          <img src="../assets/img/sophrologie/icons/stones.png" alt="stones" style="height: 40px">
        </div>
        <div class="col-8 d-flex align-content-center">
          <h4 class="p-0 m-0">Maintenir votre concentration</h4>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-4 text-end pr-3">
          <img src="../assets/img/sophrologie/icons/motivation.png" alt="motivation" style="height: 40px">
        </div>
        <div class="col-8 d-flex align-content-center">
          <h4 class="p-0 m-0">Surmonter la procrastination</h4>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-4 text-end pr-3">
          <img src="../assets/img/sophrologie/icons/goals.png" alt="goals" style="height: 40px">
        </div>
        <div class="col-8 d-flex align-content-center">
          <h4 class="p-0 m-0">Atteindre vos objectifs</h4>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-4 text-end pr-3">
          <img src="../assets/img/sophrologie/icons/sleep.png" alt="sleep" style="height: 40px">
        </div>
        <div class="col-8 d-flex align-content-center">
          <h4 class="p-0 m-0">Équilibrer votre sommeil</h4>
        </div>
      </div>
    </div>

    <hr class="divider" />

    <div class="row my-5">
      <div class="col-lg-12 text-center">
        <h2>Témoignages</h2>
        <div id="testimonials" class="carousel slide pt-4" data-bs-ride="carousel">
          <div class="carousel-inner" style="min-height: max-content">
            <div class="carousel-item active">
              <div class="d-flex justify-content-center">
                <p class="w-75">"Grâce aux séances de sophrologie avec Stéphanie, j'ai réussi à
                  mieux gérer mon stress au quotidien. Je recommande vivement !"</p>
              </div>
              <h4>- Marie</h4>
            </div>
            <div class="carousel-item">
              <div class="d-flex justify-content-center">
                <p class="text-center w-75">"Les techniques de relaxation m'ont aidé à améliorer mon sommeil.<br/>Merci Stéphanie pour votre aide précieuse!"</p>
              </div>
              <h4>- Jean</h4>
            </div>
            <div class="carousel-item">
              <div class="d-flex justify-content-center">
                <p class="text-center w-75">"Les séances sont très apaisantes et m'ont aidé à retrouver une certaine sérénité dans ma vie."</p>
              </div>
              <h4>- Anne</h4>
            </div>
          </div>
          <a class="carousel-control-prev" href="#testimonials" role="button" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a class="carousel-control-next" href="#testimonials" role="button" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HomeComponent'
}
</script>

<style>
.masthead.bg-home-1 {
  background: radial-gradient(rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 70%), url("../assets/img/bg-masthead.jpeg");
}
</style>

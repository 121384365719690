<template>
  <footer class="bg-light py-5">
    <div class="container px-4 px-lg-5">
      <div class="small text-center text-muted">© Stéphanie Vaudelin - 2024<br/>Il est important de se rappeler que je ne suis
        pas médecin, ni psychiatre, ni psychothérapeute. Toute question d’ordre médical est à poser à votre médecin. En
        aucun cas je ne peux me substituer au corps médical pour faire un diagnostic ou traiter une maladie.
        Site réalisé
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style>
/* Ajoutez vos styles ici */
</style>